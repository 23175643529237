export const environment = {
    production: true,
    BASE_URL: 'https://qa.webapi.digitalpassportsystem.hitachienergy.com:443/',
    appInsights: '3938991c-c1e5-45d2-ab54-73bbdcbc8aa5',
    clientID: 'e8affdde-946e-4db6-afce-a647c53c4aeb',
    scopes: ['User.ReadBasic.All', 'e398c7b3-c931-44d8-9c4b-5493a054ae73/Access_dps_api', 'User.Read'],
    redirectURi: 'https://qa.digitalpassportsystem.hitachienergy.com',
    subscription: '7b2a063317f74aa4a6bb6d30b172ce29',
    authority: 'https://login.microsoftonline.com/7831e6d9-dc6c-4cd1-9ec6-1dc2b4133195',
    blobContainer: 'pglz01-ne-stblob-dps-202',
    azureSearchKey: 'TEYKCAC2QxtwiybDSN7HYED1Zd3SLER6pfxLbG1VEgAzSeDsKF9V',
    azureSearchURL: 'https://qa.search.digitalpassportsystem.hitachienergy.com',
    bingCredential: 'AhHGm49KKwySazCHIgNbmzE0l9hNR35wKyMGwJbhrKUmg_AIpLyjX-uqiBMGUdIf',
    azureMapKey: 'LPb8Ufe3pAHt-c2GlYCA75lWmMgXTRe8SFOsRxqOl4E',
    title: '-  QA',
    blobContainerCustomReport: 'pglz01-ne-stblob-dps-203',
    downloadZip: 'https://qa.export.digitalpassportsystem.hitachienergy.com/api/zip?code=uNEz2W6WzCd5c2tllFXO4lZFmc6X4FgaCOHJvqOXF3Q0AzFuoOrINQ==',
    CCRPUrl: 'https://test.ccrp.hitachienergy.com/en/complaint/',
    clientSecret: 'test',
    isCCRP: 'yes',
    CCRPAppId: '9AAG029588',
    ServIsAppId: '9AAG029556',
    organizationName: 'HE',
    signlarUrl: 'https://qa.download.digitalpassportsystem.hitachienergy.com/api/negotiate?code=2EEYL-rH_zUKO4EhZhuulaw6CFue2idnj_hr91-OsMi-AzFuinNRYg==',
    productHierarchy: 'https://qa.export.digitalpassportsystem.hitachienergy.com/api/ProductHierarchy?code=4a_Xg52Fqkrc8lf8g5hLso4637b1MrKamD96ZuKEN7DHAzFuuunJHA==',
    generateReportProductionTimeCaptured: 'https://qa.export.digitalpassportsystem.hitachienergy.com/api/GenerateReportForProductionTimeCaptureDetails?code=CLEfLR6PF6VjCBeKJdA8uM0z-orPoO31nKVb854K8ozhAzFuMm9fPg==',
    environment: 'HITACHI',
    downloadQCZip: 'https://qa.export.digitalpassportsystem.hitachienergy.com/api/ProductMultipleDocumentsDownload?code=Bd-BtG97QpmbgcZM8a6ioohWxkWllLTdI2DK-53VTN8YAzFuTJIahw=='
};
